<template>
  <b-card-code title="Your Referral Link">
    <b-card-text>Refer People to Lordtech and earn money doing it</b-card-text>
    <div class="">
      <b-row>

        <b-col md="10" sm="10" lg="10"  class="mt-1"> <b-form-group class="mb-0 mr-1">
        <b-form-input
          v-model="mainurl"
          readonly
        />
      </b-form-group>
      
     
      
      </b-col>
       <b-col md="2" sm="2" lg="2" class="mt-1">
           <b-button
        v-clipboard:copy="mainurl"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="primary"
      >
        Copy Referral Link!
      </b-button>
</b-col>
      </b-row>

      <!-- form input -->
     

      <!-- button -->
      
    </div>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BFormInput, BFormGroup, BButton, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'

export default {
  components: {
    BCardCode,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      message: 'Copy Me!',
      mainurl: window.location.origin + "/refer/"+ this.$store.getters['getuser'].username,

  
    }
  },
  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Referral link copied',
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy link!',
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
