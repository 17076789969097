<template>
  <b-card

    class="earnings-card"
  >
    <b-row class="match-height">
      <b-col cols="6">
        <b-card-title class="mb-1">
          Referral Earnings
        </b-card-title>
       
        <h3 class="mb-1">
          ₦{{this.$store.getters['getuser'].ref_balance}}
        </h3>
      <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-if="this.$store.getters['getuser'].ref_balance >= 100"
      variant="primary"
      @click="convert"
    >
      Convert
    </b-button>
      </b-col>
 
      <b-col cols="6">
        <!-- chart -->
        
      </b-col>



   
     

    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BButton, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import useServices from "@/auth/jwt/userServices"
const $earningsStrokeColor2 = '#28c76f66'
const $earningsStrokeColor3 = '#28c76f33'
export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: ['App', 'Service', 'Product'],
          stroke: { width: 0 },
          colors: [$earningsStrokeColor2, $earningsStrokeColor3, $themeColors.success],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'App',
                    formatter() {
                      return '53%'
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    }
  },


  methods: {
   convert() {
      this.$swal({
        title: 'Transfer Referral Earnings To Wallet',
        input: 'text',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off',
          type: 'number',
          placeholder: "Amount you wish to convert",
          
        },
        showCancelButton: true,
        confirmButtonText: 'Covert',
        showLoaderOnConfirm: true,
        preConfirm(login) {
          if (!login) return null
          return  useServices.convertwallet({amount: parseInt(login)})
            .then(response => {
              
            
              return response.data;
            })
            .catch(error => {
              console.log(error)
            })
        },
      }).then(result => {

      
        if (result.value.status == true) {
          this.$swal({
        title: result.value.data,
       
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
        }else {


  this.$swal({
        title: result.value.data,
       
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })

        }
      })
    
   }
  },
}
</script>
