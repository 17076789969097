<template>
<spinner v-if="load"/>
  <b-card
    v-else-if="transactions.length != 0"
    class="card-transaction"
    no-body
  >


    <b-card-header>
      <b-card-title>Last 5 Wallet Transactions</b-card-title>


<router-link :to="{name: 'wallettrans'}"><small>View all</small></router-link>
    </b-card-header>

    <b-card-body>


      <div
        v-for="transaction in transactions"
        :key="transaction.id"
        class="transaction-item"
      >
     
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              variant="primary"
              :src="mainurl + transaction.image"
            >
              <feather-icon
                size="18"
                icon="CreditCardIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ transaction.narration }}
            </h6>
             <b-badge :variant="statusVariant(transaction.type)">
            {{ statusVariantText(transaction.type) }}
          </b-badge>
          </b-media-body>
        </b-media>
        

 

<div
          class="font-weight-bolder"
          :class="transaction.deduction ? 'text-danger':'text-success'"
        >
          ₦ {{ transaction.amount }}
        </div>
      </div>
    </b-card-body>
  </b-card>


<b-card v-else><b-card-text >No Transaction Yet</b-card-text></b-card>

</template>

<script>
import {
  BCard, BCardHeader, BBadge, BCardTitle, BCardText, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import useServices from "@/auth/jwt/userServices"
import Spinner from '../Spinner.vue'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
export default {
  components: {
    BCard,
    BCardHeader,
    Spinner,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BCardText,
    BBadge,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  props: ['transactions'],
  data() {
      return {
          load: false,
          mainurl: jwtDefaultConfig.mainurl,
          data: [],
      }
  },
  created() {
      // this.gettransactions()
  },
  computed: {
         statusVariantText() {
      const statusColor = {
        /* eslint-disable key-spacing */
   
        "c" : 'Credit',
       "d"   : 'Debit',
      
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
      },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
       
        "c" : 'light-success',
        "d"    : 'light-danger',
       
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },




  methods: {
           async gettransactions()    {

           try {
               
           this.load = true;
            await useServices.gettransactions().then((result)=> {
                console.log(result.data.data)
               this.data = result.data.data.slice(0, 5);
            });

            if (result.data.status == false) {
                // this.$router.back();
            }

            this.load = false;

           } catch (error) {
                this.load = false;
            //   this.$router.back();

           }

        }
  },
}
</script>
