<template>
  <div>
    <b-row class="match-height">


<b-col>
   <b-card
          border-variant="primary"
          title="Our Mobile App Now Available 🔥 🔥 🔥 🔥"
          bg-variant="transparent"
          class="shadow-none text-center"
        >
          <b-card-text>
           Join millions of people globally, to recharge and pay your bills the secure and seamless way.
          </b-card-text>
          <a href="https://apps.apple.com/app/id1621718450" target="_blank"> <b-img class="mr-2 mt-1" src="https://lordtech.com/wp-content/uploads/2021/07/app-store.png" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.lordtech.lordtechbills" target="_blank"> <b-img class="mr-2 mt-1" src="https://lordtech.com/wp-content/uploads/2021/07/play-store.png" /></a>
       
        
        </b-card>
</b-col>
      <b-col md="12">
      <referral />
      </b-col>



       <b-col
        xl="4"
        md="6"
      >
        <wallet />
      </b-col>


       <b-col
        xl="4"
        md="6"
      >
        <ref-wallet />
      </b-col>
     <b-col
        lg="2"
        cols="6"
      >
        <total-deposits :total="totaldepo"/>
      </b-col>
      <b-col
        lg="2"
        cols="6"
      >
        <total-transactions :total="totaltrans" />
      </b-col>






      
      
    </b-row>



<h4 class="card-title">Quick Links</h4>
     


      <b-row>
       <b-col
        lg="2"
        cols="6"
      >
        <quik-links-card
          hide-chart
          color="primary"
          icon="CreditCardIcon"
          statistic="Deposit"
          link="depositmethod"
      
        />
      </b-col>

       <b-col
        lg="2"
        cols="6"
      >
        <quik-links-card
          hide-chart
          color="dark"
          icon="SmartphoneIcon"
          statistic="Airtime"
          link="buy-airtime"
        />
      </b-col>
      <b-col
        lg="2"
        cols="6"
      >
        <quik-links-card
          hide-chart
          color="secondary"
          icon="WifiIcon"
          statistic="Data"
          link="buy-data"
         
      
        />
      </b-col>
   <b-col
        lg="2"
        cols="6"
      >
        <quik-links-card
          hide-chart
          color="danger"
          icon="MonitorIcon"
          statistic="CableTv"
           link="cable-tv"
      
        />
      </b-col>
     <b-col
        lg="2"
        cols="6"
      >
        <quik-links-card
          hide-chart
          color="warning"
          icon="ZapIcon"
          statistic="Electricity"
           link="electricity"
      
        />
      </b-col>
     <b-col
        lg="2"
        cols="6"
      >
        <quik-links-card
          hide-chart
          color="info"
          icon="BookIcon"
          statistic="Education"
          link="education"
      
        />
      </b-col>




       <!-- Browser States Card -->
      <b-col
        lg="4"
        md="6"
      >
        <transactions :transactions="trans" />
      </b-col>
      <!--/ Browser States Card -->



        <b-col
        lg="4"
        md="6"
      >
        <depositdash :transactions="depos" />
      </b-col>



      <!-- Goal Overview Card -->
     
      <!--/ Goal Overview Card -->

      <!-- Transaction Card -->
      <b-col
        lg="4"
        md="6"
      >
       <referrals :referrals="referrals" />
      </b-col>
      <!--/ Transaction Card -->
    
      </b-row>





  </div>
</template>

<script>
import Referral from './components/dashboard/Referral.vue'
import { BCard, BCardText, BLink, BCol, BRow, BImg } from 'bootstrap-vue';
import Wallet from './components/dashboard/Wallet.vue';
import RefWallet from './components/dashboard/RefWallet.vue';
import TotalDeposits from './components/dashboard/TotalDeposits.vue';
import TotalTransactions from './components/dashboard/TotalTransaction.vue';
import QuikLinksCard from './components/dashboard/QuickLinks.vue';
import useServices from "@/auth/jwt/userServices"
// import Spinner from '../Spinner.vue'
// import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import Transactions from './components/dashboard/Transactions.vue';
import Depositdash from './components/dashboard/Depositsdash.vue';
import Referrals from './components/dashboard/Referrals.vue';
import Overview from './components/dashboard/Overview.vue';
export default {
  components: {
    QuikLinksCard,
    Transactions,
    Overview,
    Referrals,
    BCard,
    BImg,
    Referral,
    RefWallet,
    Depositdash,
    TotalDeposits,
    TotalTransactions,
    Wallet,
    BCardText,
    BLink,
    BCol,
    BRow,
  },


data() {
  return {
    trans: [],
    depos: [],
    referrals: [],
    totalref: 0,
    totaldepo: 0,

    totaltrans : 0,
  }
},
created() {
  this.gettransactions();
  this.getdeposits();
  this.getreferrals();
},



  methods: {
 async getdeposits()    {

           try {
               
           this.load = true;
            await useServices.getdeposits().then((result)=> {
              
               this.depos = result.data.data.deposits.slice(0, 5);;
               this.totaldepo = result.data.data.total;
            });

            if (result.data.status == false) {
                // this.$router.back();
            }

            this.load = false;

           } catch (error) {
                this.load = false;
            //   this.$router.back();

           }

        },


           async getreferrals()    {

           try {
               
           this.load = true;
            await useServices.getreferrals().then((result)=> {
               if (result.data.status != false) {
                console.log(result.data.data)
               this.referrals = result.data.data.slice(0, 5);
         
            }
               
            });

           

            this.load = false;

           } catch (error) {
                this.load = false;
            //   this.$router.back();

           }

        },


           async gettransactions()    {

           try {
               
           this.load = true;
            await useServices.gettransactions().then((result)=> {
                console.log(result.data.data)
               this.trans = result.data.data.transactions.slice(0, 5);
               this.totaltrans = result.data.data.total;
            });

            if (result.data.status == false) {
                // this.$router.back();
            }

            this.load = false;

           } catch (error) {
                this.load = false;
            //   this.$router.back();

           }

        }
  },
}
</script>

<style>

</style>
