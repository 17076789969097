<template>
  <b-card
   
    class="card-congratulation-medal"
  >
    <h5>Your LordTech Wallet 🎉 !</h5>
   
    <h3 class="mb-75 mt-2 pt-50">
      <b-link>₦{{this.$store.getters['getuser'].balance}}</b-link>
    </h3>
    <router-link  :to="{name: 'depositmethod'}"
    >
     <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
    >
      Deposit
    </b-button>
    </router-link>
   
    <b-img
      :src="require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BLink, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },


  mounted() {
   this.$store.dispatch('checkvavailability');
  },
//   props: {
//     data: {
//       type: Object,
//       default: () => {},
//     },
//   },
  methods: {
    kFormatter,
  },
}
</script>
