<template>
<spinner v-if="load"/>
  <b-card
  v-else-if="referrals.length != 0"
    no-body
    class="card-browser-states"
  >
    <b-card-header>
      <div>
        <b-card-title>Last 5 Referrals</b-card-title>
        <!-- <b-card-text class="font-small-2">
          Counter August 2020
        </b-card-text> -->
      </div>

     <router-link :to="{name: 'my-referrals'}"><small>View all</small></router-link>
    </b-card-header>

    <!-- body -->
    <b-card-body>

      <div
        v-for="ref in referrals"
        :key="ref.email"
        class="browser-states"
      >
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar
            size="lg"
              :src="ref.profile_photo_url"
              alt="browser img"
            />
          </b-media-aside>
          <b-media-body>
            <h6 class="align-self-center my-auto">
              {{ ref.name }}
            </h6>

            @{{ref.username}}
          </b-media-body>
        </b-media>
        <div class="d-flex align-items-center">
        
           {{ref.created_at |  moment("from", "now")}}
        </div>
      </div>
    </b-card-body>
    <!--/ body -->
  </b-card>


<b-card v-else><b-card-text >No Referrals Yet</b-card-text></b-card>

</template>

<script>
import {
  BCard, BCardHeader, BAvatar, BCardTitle, BCardText, BCardBody, BMedia, BMediaAside, BMediaBody, BImg, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Spinner from '../Spinner.vue'
import { $themeColors } from '@themeConfig'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'
export default {
  props: ['referrals'],
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    Spinner,
      BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    VueApexCharts,
  },
  data() {
    return {
      chartData: [],
      load: false,
      chartClone: {},
      chartColor: [$themeColors.primary, $themeColors.warning, $themeColors.secondary, $themeColors.info, $themeColors.danger],
      chartSeries: [54.4, 6.1, 14.6, 4.2, 8],
      browserData: [
        {
          browserImg: require('@/assets/images/icons/google-chrome.png'),
          name: 'Google Chrome',
          usage: '54.4%',
        },
        {
          browserImg: require('@/assets/images/icons/mozila-firefox.png'),
          name: 'Mozila Firefox',
          usage: '6.1%',
        },
        {
          browserImg: require('@/assets/images/icons/apple-safari.png'),
          name: 'Apple Safari',
          usage: '14.6%',
        },
        {
          browserImg: require('@/assets/images/icons/internet-explorer.png'),
          name: 'Internet Explorer',
          usage: '4.2%',
        },
        {
          browserImg: require('@/assets/images/icons/opera.png'),
          name: 'Opera Mini',
          usage: '8.%',
        },
      ],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
    }
  },
  created() {
    for (let i = 0; i < this.browserData.length; i += 1) {
      const chartClone = JSON.parse(JSON.stringify(this.chart))
      chartClone.options.colors[0] = this.chartColor[i]
      chartClone.series[0] = this.chartSeries[i]
      this.chartData.push(chartClone)
    }
  },
}
</script>
